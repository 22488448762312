<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="container">
        <div class="form-row">
          <div class="col-12 col-md-4 mb-3 d-print-none">
            <div class="w-100 p-3 shadow-sm bg-white rounded-lg">
              <profile-loader v-if="loading" />
              <profile-menu v-else :profile="profile" />
            </div>
          </div>
          <div class="col-12 col-md-8 mb-3" v-if="!loading">
            <div class="mb-3 w-100 shadow-sm bg-white rounded-lg" v-if="route==='/profile'">
              <div class="col-12 text-left py-3 border-bottom clearfix">
                <div class="form-row">
                  <div class="col my-auto">
                    <h5 class="float-left mb-0">{{$t('editProfile')}}</h5>
                  </div>
                  <div class="col-auto">
                    <a class="btn btn-sm btn-yellow-transparent" v-if="disabled" @click="disabled=!disabled">
                      <b-icon icon="pencil"></b-icon> {{$t('edit')}}
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 p-3">
                <b-form class="row">

                  <b-form-group class="col-12 col-sm-6" :label="$t('firstName')">
                    <b-form-input :disabled="disabled"
                                  type="text" v-model="firstName" required>
                    </b-form-input>
                  </b-form-group>


                  <b-form-group class="col-12 col-sm-6" :label="$t('lastName')">
                    <b-form-input :disabled="disabled"
                                  type="text" v-model="lastName" required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group class="col-12 col-sm-6" :label="$t('country')">
                    <country-select className="form-control"
                                    :country="country"
                                    :disabled="disabled"
                                    :autocomplete="true"
                                    v-model="country"
                                    :usei18n="false"></country-select>
                  </b-form-group>
                  <b-form-group class="col-12 col-sm-6" :label="$t('phoneNumber')">
                    <b-form-input :disabled="disabled"
                                  type="text" v-model="phone" required>
                    </b-form-input>
                  </b-form-group>

                  <b-form-group class="col-12 col-sm-6" :label="$t('street')">
                    <b-form-input :disabled="disabled"
                                  type="text" v-model="street" required>
                    </b-form-input>
                  </b-form-group>

                  <b-form-group class="col-12 col-sm-6" :label="$t('doorNumber')">
                    <b-form-input :disabled="disabled"
                                  type="text" v-model="houseNumber" required>
                    </b-form-input>
                  </b-form-group>

                  <b-form-group class="col-12 col-sm-6" :label="$t('city')">
                    <b-form-input :disabled="disabled"
                                  type="text" v-model="city" required>
                    </b-form-input>
                  </b-form-group>

                  <b-form-group class="col-12 col-sm-6" :label="$t('postalCode')">
                    <b-form-input :disabled="disabled"
                                  type="text" v-model="postalCode" required>
                    </b-form-input>
                  </b-form-group>

                  <b-form-group class="col-12">
                    <b-form-checkbox name="subscribe" :disabled="disabled"
                                     v-model="subscribe"
                                     value="1" unchecked-value="0">
                      <span v-html="$t('subscribe')"></span>
                    </b-form-checkbox>
                  </b-form-group>


                  <b-form-group class="col-12">
                    <b-alert :show="state==='success'" class="m-0" variant="success">{{message}}</b-alert>
                    <b-alert :show="state==='error'" class="m-0" variant="danger">{{message}}</b-alert>
                  </b-form-group>

                  <div class="col-12 py-2 text-center" v-if="!disabled">
                    <b-button class="mr-2" v-if="!saving" @click="cancelEdit" variant="danger">{{$t('cancel')}}</b-button>
                    <b-button variant="success" :disabled="saving"
                    @click="updateProfile">
                      <b-spinner v-if="saving" small class="mr-2"></b-spinner>
                      {{$t('update')}}
                    </b-button>
                  </div>

                </b-form>
              </div>

            </div>
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileLoader from "@/components/Profile/ProfileLoader";
import ProfileMenu from "@/components/Profile/ProfileMenu";
export default {
  name: "Profile",
  components: {ProfileMenu, ProfileLoader},
  created() {
    this.loadProfile();
  },
  methods : {
    updateProfile() {
      this.state = "";
      this.saving = true;
      this.$http.post("profile/update",{
        firstName : this.firstName,
        lastName : this.lastName,
        country : this.country,
        phoneNumber : this.phone,
        street : this.street,
        houseNumber : this.houseNumber,
        city : this.city,
        postalCode : this.postalCode,
        subscribe : this.subscribe,
      }).then(response=>{
        if(response.data.error===undefined) {
          this.state = "error";
          this.message = this.$t("errors.unableToUpdate");
        } else if(response.data.error) {
          this.state = "error";
          this.message = response.data.message;
        } else {
          this.state = "success";
          this.message = response.data.message;
          this.disabled = true;
          this.profile.phone = this.phone;
          this.profile.name = this.firstName+" "+this.lastName;
          this.$store.commit("setLoginName",this.firstName);
          setTimeout(()=>{
            this.state = "";
          },3000);
        }
      }).catch(()=>{
        this.state = "error";
        this.message = this.$t("errors.unableToUpdate");
      }).finally(()=>{
        this.saving = false;
      })
    },
    cancelEdit() {
      this.disabled = true;
      this.phone = this.profile.phone;
      this.firstName = this.profile.firstName;
      this.lastName = this.profile.lastName;
      this.country = this.profile.country;
      this.street = this.profile.street;
      this.houseNumber = this.profile.houseNumber;
      this.city = this.profile.city;
      this.postalCode = this.profile.postalCode;
      this.subscribe = this.profile.subscribe;
    },
    loadProfile() {
      this.loading = true;
      this.$http.get("profile").then(response=>{
        if(response.data.error || response.data.error===undefined) {
          this.$router.push("/");
        } else {
          this.loading = false;
          this.form = response.data.profile;
          this.profile = response.data.profile;
          this.phone = response.data.profile.phone;
          this.firstName = response.data.profile.firstName;
          this.lastName = response.data.profile.lastName;
          this.country = response.data.profile.country;
          this.street = response.data.profile.street;
          this.houseNumber = response.data.profile.houseNumber;
          this.city = response.data.profile.city;
          this.postalCode = response.data.profile.postalCode;
          this.subscribe = response.data.profile.subscribe;
        }
      }).catch(()=>{
        this.$router.push("/");
      });
    }
  },
  data() {
      return {
        route : this.$route.path,
        loading : true,
        disabled : true,
        saving : false,
        message : "",
        state : "",
        profile: {},
        phone : "",
        firstName : "",
        lastName : "",
        country : "",
        street : "",
        houseNumber : "",
        city : "",
        postalCode : "",
        subscribe : "0",
      }
  },
  watch:{
    $route (to){
      this.route = to.path;
    }
  }
}
</script>
