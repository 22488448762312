<template>
  <div>
    <div class="row border-bottom pb-4">
      <div class="col-12 text-center pb-2">
        <b-icon icon="person-fill" font-scale="2.5" class="me-1 text-yellow"></b-icon>
      </div>
      <div class="col-12 text-center">
        <b>{{profile.name}}</b> <br>
        <small>{{profile.phone}}</small><br>
        <small>{{profile.email}}</small>
      </div>
    </div>
    <div class="row">
      <ul class="list-group list-group-flush w-100">
        <router-link to="/profile" class="list-group-item text-black" href="">
          <b-icon icon="person" class="mr-1"></b-icon> {{$t('myAccount')}}
        </router-link>
        <router-link to="/profile/orders" class="list-group-item text-black" href="">
          <b-icon icon="calendar-week" class="mr-1"></b-icon> {{$t('myBookings')}}
        </router-link>
        <a class="list-group-item text-black" @click="openChangeModal">
          <b-icon icon="shield-lock" class="mr-1"></b-icon> {{$t('changePassword')}}
        </a>
      </ul>
    </div>




    <b-modal v-model="showModal" centered no-fade
             size="sm" lazy
    :title="$t('changePassword')"
    :hide-header-close="saving"
    :no-close-on-backdrop="saving"
    :no-close-on-esc="saving">


      <b-form-group class="col-12" :label="$t('oldPassword')">
        <b-form-input type="password" autocomplete="off" v-model="form.oldPassword" />
      </b-form-group>

      <b-form-group class="col-12" :label="$t('newPassword')">
        <b-input-group>
          <template #append>
            <b-input-group-text style="cursor:pointer;" @click="isPassword=!isPassword">
              <b-icon-eye v-if="isPassword" />
              <b-icon-eye-slash v-else />
            </b-input-group-text>
          </template>
          <b-form-input autocomplete="off" v-model="form.newPassword"
          :type="(isPassword) ? 'password' : 'text'"></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group class="col-12" :label="$t('confirmPassword')">
        <b-form-input autocomplete="off" v-model="form.confirmPassword" type="password"/>
      </b-form-group>

      <div class="col-12">
        <b-alert :show="savingError" class="m-0" variant="danger">
          {{errorMessage}}
        </b-alert>
        <b-alert :show="savingSuccess" class="m-0" variant="success">
          {{errorMessage}}
        </b-alert>
      </div>

      <template #modal-footer>
        <b-button v-if="!saving" @click="showModal=false" variant="danger">{{$t('cancel')}}</b-button>
        <b-button variant="success" :disabled="saving" @click="changePassword">
          <b-spinner v-if="saving" small class="mr-2" />
          {{$t('update')}}
        </b-button>
      </template>

    </b-modal>


  </div>
</template>

<script>
export default {
  name: "ProfileMenu",
  props : {
    profile : Object
  },
  methods : {
    changePassword() {
      this.saving = true;
      this.savingError = false;
      this.savingSuccess = false;
      this.$http.post("profile/changePassword",this.form)
          .then(response=>{
            if(response.data.error===undefined) {
              this.saving = false;
              this.savingError = true;
              this.errorMessage = this.$t("unableToChange");
            } else if(response.data.error) {
              this.saving = false;
              this.savingError = true;
              this.errorMessage = response.data.message;
            } else {
              this.savingSuccess = true;
              this.errorMessage = response.data.message;
              setTimeout(()=>{
                this.saving = false;
                this.showModal = false;
              },2000);
            }
          }).catch(()=>{
            this.saving = false;
            this.savingError = true;
            this.errorMessage = this.$t("unableToChange");
      });
    },
    openChangeModal() {
      this.form = {
        oldPassword : "",
        newPassword : "",
        confirmPassword : ""
      };
      this.savingError = false;
      this.savingSuccess = false;
      this.showModal = true;
    }
  },
  data() {
    return {
      showModal : false,
      savingError : false,
      savingSuccess : false,
      errorMessage : "",
      isPassword : true,
      form : {
        oldPassword : "",
        newPassword : "",
        confirmPassword : ""
      },
      saving : false
    }
  }
}
</script>

<style scoped>

</style>