<template>
  <div>
    <div class="row border-bottom pb-4">
      <div class="col-12 text-center pb-2">
        <b-skeleton-icon
            class="mr-1 text-yellow"
            icon="person-fill"
            :icon-props="{ fontScale: 2.5, variant: 'dark' }"
        ></b-skeleton-icon>
      </div>
      <div class="col-12 text-center">
        <b-skeleton width="60%"></b-skeleton>
        <b-skeleton width="80%"></b-skeleton>
        <b-skeleton></b-skeleton>
      </div>
    </div>

    <div class="row border-bottom" v-for="(link,index) in links" :key="'linkLoader'+index">
      <div class="col-auto py-2">
        <b-icon :icon="link.icon"></b-icon>
      </div>
      <div class="col py-2 my-auto">
        <b-skeleton></b-skeleton>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ProfileLoader",
  data() {
    return {
      links : [
        { icon : "person" },
        { icon : "calendar-week" },
        { icon : "shield-lock" },
      ]
    }
  }
}
</script>

<style scoped>

</style>